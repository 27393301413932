import React, { useState, useRef, useEffect } from 'react';
import styled from 'styled-components';
import cn from 'classnames';

import { Link } from 'react-router-dom';

import s from './ServicesBlock.module.css';

import RightImage from './RightImage/RightImage';

import data from '../../staticSiteData/mainPage/servicesBlock/data';

import arrow from './arrow.svg';

function ServicesBlock() {
  const block = useRef();
  const tabs = data.en.menuItems.map(() => useRef());

  const [activeTabIndex, setActiveTabIndex] = useState(0);
  const [tabScrollerLeft, setTabScrollerLeft] = useState(0);
  const [tabScrollerHeight, setTabScrollerHeight] = useState(0);
  const [tabScrollerWidth, setTabScrollerWidth] = useState(0);
  const [isAnimationDescription, setIsAnimationDescription] = useState(false);
  const [isLeftArrow, setLeftArrow] = useState(false);
  const [isRightArrow, setRightArrow] = useState(false);

  useEffect(() => {
    function handleResize() {
      getScrollerDataPosition(activeTabIndex);
      handleScroll();
    }
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [activeTabIndex]);

  useEffect(() => {
    handleScroll();
  });

  function handleScroll() {
    setLeftArrow(
      block.current.getBoundingClientRect().left + 16 >
        tabs[0].current.getBoundingClientRect().right,
    );
    setRightArrow(
      block.current.getBoundingClientRect().right - 16 <
        tabs[tabs.length - 1].current.getBoundingClientRect().left,
    );
  }

  const getScrollerDataPosition = tabId => {
    setTabScrollerLeft(tabs[tabId].current.offsetLeft);
    setTabScrollerWidth(tabs[tabId].current.offsetWidth);
    setTabScrollerHeight(tabs[tabId].current.offsetHeight);
  };
  const handleTabClick = e => {
    const tabId = Number(e.target.dataset.id);
    if (activeTabIndex !== tabId) {
      setActiveTabIndex(tabId);
      setIsAnimationDescription(true);
      setTimeout(() => {
        setIsAnimationDescription(false);
      }, 1000);
    }
  };
  const activeTabData = data.en.menuItems[activeTabIndex];

  return (
    <div className={s.blockWrapper} id={'service-block'}>
      <h2 className={s.mainTitle}>{data.en.title}</h2>

      <TitlesLine>
        <LeftArrow visible={isLeftArrow} />
        <RightArrow visible={isRightArrow} />

        <div className={s.tabs} ref={block} onScroll={handleScroll}>
          <div
            className={s.tabScroller}
            style={{
              left: tabScrollerLeft + 'px',
              width: tabScrollerWidth + 'px',
              height: tabScrollerHeight + 'px',
            }}
          />
          {data.en.menuItems.map((tab, i) => (
            <div
              key={tab.tabName}
              onClick={handleTabClick}
              data-id={i}
              className={s.tabTitle}
              ref={tabs[i]}
            >
              <div className={s.tabNameText}>{tab.tabName}</div>
            </div>
          ))}
        </div>
      </TitlesLine>

      <div className={s.tabWrapper}>
        <div className={s.left}>
          <div
            className={cn(s.animateBox, {
              [s.animationLeft]: isAnimationDescription,
            })}
          >
            <div className={s.title}>{activeTabData.tabName}</div>
            <div className={s.description}>{activeTabData.text}</div>
            <div className={s.buttonRow}>
              <Link className={s.button} to={'/sign-up-pet-owner'}>
                {activeTabData.leftButton.text}
              </Link>
              <Link className={s.buttonInherit} to={'/sign-up-provider'}>
                {activeTabData.rightButton.text}
              </Link>
            </div>
          </div>
        </div>
        <RightImage img={activeTabData.img} index={activeTabIndex} />
      </div>
    </div>
  );
}

const LeftArrow = styled.div`
  display: ${props => !props.visible && 'none'};
  position: absolute;
  z-index: 10;
  left: -16px;
  top: 9px;
  width: 18px;
  height: 18px;
  background: rgba(255, 255, 255, 0) url(${arrow});
  background-size: cover;
  transform: rotate(-90deg);
`;

const RightArrow = styled(LeftArrow)`
  left: auto;
  right: -8px;
  transform: rotate(90deg);
`;

const TitlesLine = styled.div`
  display: flex;
  position: relative;
  width: 100%;
`;

export default ServicesBlock;
