import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { createPage } from 'libs/ssr';

import PetCard from 'ui/_components/PetCard/PetCard';
import ReivwSlider from 'ui/_components/ReviewSlider/ReviewSlider';
import { JoinUsBlock } from 'ui/_components/JoinUsBlock/JoinUsBlock';
import VideoPlayer from 'ui/_components/VideoPlayer/VideoPlayer';
import ServicesBlock from 'ui/_components/ServicesBlock/ServicesBlock';
import featureData from 'ui/staticSiteData/mainPage/petCards';
import { PublicPageTemplate } from 'ui/templates';

// @ts-ignore
import { defaultMetaTags } from 'default-meta';

import { ScrollSideContent } from 'libs/react-scroll-side-content';

import s from './home.page.module.css';
import { useCurrentProfile } from '../features/profile';
import { MetaTags } from '../libs/metaTags';

function HomePage() {
  // @ts-ignore
  return (
    <PublicPageTemplate>
      <MetaTags {...metaTags} />

      <div className={s.pageWrapper}>
        <section className={s.headerBlock}>
          <div className={s.blockContainer}>
            <div className={s.whiteText}>Organize Your World With Pawzr</div>
            <h1 className={s.title}>Pets Are Our World Too</h1>
            <div className={s.description}>
              We began with a simple philosophy:
              <br />
              Caring for the pets we love should not be difficult.
              <br />
              <br />
              With that mission in mind, we set out to create the most
              intuitive, user-friendly system for pet owners and service
              providers. A software solution made by pet lovers, for pet lovers.
              We call it Pawzr.
            </div>
            <div className={s.mobileDescription}>
              We Set out to to create the most intuitive, user-friendly system
              for pet owners and service providers. A software solution made by
              pet lovers, for pet lovers. We call it Pawzr.
            </div>
            <ProfileButton />
          </div>
        </section>
        <VideoPlayer
          className=""
          videoSrc={{
            mp4: 'https://pawzr.s3.us-east-2.amazonaws.com/background.mp4',
            webm: 'https://pawzr.s3.us-east-2.amazonaws.com/background.webm',
          }}
        />
        <div className={s.featuresBlock}>
          <div className={s.blockContainer}>
            <div className={s.column}>
              <h2 className={s.title}>Pawzr Features</h2>
              {featureData.en.slice(0, 2).map(item => (
                <PetCard className={s.featureItem} key={item.title} {...item} />
              ))}
              <div className={s.pawz}>
                <Link className={s.startButton} to="/sign-up-pet-owner">
                  Start for free
                </Link>
              </div>
            </div>
            <div className={s.column}>
              {featureData.en.slice(2).map(item => (
                <PetCard className={s.featureItem} key={item.title} {...item} />
              ))}
            </div>
          </div>
        </div>
        <div>
          <div className={s.featuresBlockMobile}>
            <h2 className={s.title}>Pawzr Features</h2>
            <ScrollSideContent>
              <FeatureMobile>
                {featureData.en.map(item => (
                  <div key={item.title} className={s.slideWrapper}>
                    <PetCard className={s.featureItem} {...item} />
                  </div>
                ))}
              </FeatureMobile>
            </ScrollSideContent>
          </div>
        </div>
        <div className={s.servicesBlock}>
          <ServicesBlock />
        </div>
        <div className={s.reviewSlider}>
          <div className={s.blockContainer}>
            <h2 className={s.title}>Reviews</h2>
          </div>
          <ReivwSlider className={s.slider} />
        </div>
        <JoinUsBlock />
      </div>
    </PublicPageTemplate>
  );
}

const FeatureMobile = styled.div`
  padding: 0 15px;
  display: flex;
`;

const metaTags = {
  ...defaultMetaTags,
  title:
    'Pets are our world too, Pawzr is where you can keep it all organized | Pawzr',
};

export default createPage(HomePage, {
  renderMetaTags: (url, _, host) => <MetaTags {...metaTags} url={url} />,
});

function ProfileButton() {
  const { isPetOwner, isServiceProvider } = useCurrentProfile();

  if (isPetOwner) {
    return (
      <Link to={'/pet-owner-profile'} className={s.signUpButton}>
        Profile
      </Link>
    );
  }

  if (isServiceProvider) {
    return (
      <Link to={'/service-provider-profile'} className={s.signUpButton}>
        Profile
      </Link>
    );
  }

  return (
    <Link className={s.signUpButton} to="/sign-up-pet-owner">
      Sign Up
    </Link>
  );
}
