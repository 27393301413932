import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import s from './Popup.module.css';

export function Popup(props) {
  const { isOpen, children, footer, className = '' } = props;

  return (
    <div className={cn(s.PopupWrapper, { [s.hidden]: !isOpen })}>
      <div className={cn(s.Popup, className)}>
        <div className={s.content}>{children}</div>
        <div className={s.PopupFooter}>{footer}</div>
      </div>
    </div>
  );
}

Popup.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  footer: PropTypes.element.isRequired,
  className: PropTypes.string,
  onClose: PropTypes.func,
};
