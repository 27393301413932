import React, { useState } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import s from './PetCard.module.css';

function PetCard(props) {
  const { title, pathToImage, className, backgroundSize } = props;
  const [isShow, setShow] = useState(false);
  return (
    <div
      className={cn(s.petCardContainer, { [s.active]: isShow }, className)}
      style={{ backgroundImage: `url('${pathToImage}')`, backgroundSize }}
    >
      <div className={s.title}>{title}</div>
      <div onClick={() => setShow(!isShow)} className={s.showButton} />
      <div className={s.infoWrapper}>
        <div className={s.infoText}>{props.text}</div>
        <a href="/for-pet-owners" className={s.moreButton}>
          More
        </a>
      </div>
    </div>
  );
}

PetCard.propTypes = {
  title: PropTypes.string.isRequired,
  pathToImage: PropTypes.string.isRequired,
  className: PropTypes.string,
};

export default PetCard;
