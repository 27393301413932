import React from 'react';
import cn from 'classnames';

import s from './RightImage.module.css';
import img1 from './img/img1.png';
import img2 from './img/img2.png';
import img3 from './img/img3.png';
import img4 from './img/img4.png';
import img5 from './img/img5.png';
import img6 from './img/img6.png';

const slides = [
  {
    img: img1,
    width: '490px',
    circles: [
      { left: '0', bottom: '-50px' },
      { left: '120px', bottom: '-117px' },
      { left: '270px', bottom: '-115px' },
      { left: '390px', bottom: '-42px' },
    ],
  },
  {
    img: img2,
    width: '410px',
    circles: [
      { left: '0', bottom: '-110px' },
      { left: '110px', bottom: '-180px' },
      { left: '240px', bottom: '-210px' },
      { left: '360px', bottom: '-145px' },
    ],
  },
  {
    img: img3,
    width: '420px',
    circles: [
      { left: '0', bottom: '-100px' },
      { left: '110px', bottom: '-160px' },
      { left: '240px', bottom: '-160px' },
      { left: '350px', bottom: '-95px' },
    ],
  },
  {
    img: img4,
    width: '410px',
    circles: [
      { left: '0', bottom: '-90px' },
      { left: '130px', bottom: '-120px' },
      { left: '260px', bottom: '-100px' },
      { left: '380px', bottom: '-35px' },
    ],
  },
  {
    img: img5,
    width: '410px',
    circles: [
      { left: '0', bottom: '-80px' },
      { left: '120px', bottom: '-130px' },
      { left: '250px', bottom: '-120px' },
      { left: '360px', bottom: '-55px' },
    ],
  },
  {
    img: img6,
    width: '410px',
    circles: [
      { left: '0', bottom: '-30px' },
      { left: '110px', bottom: '-100px' },
      { left: '240px', bottom: '-130px' },
      { left: '350px', bottom: '-75px' },
    ],
  },
];
function RightImage({ index }) {
  return (
    <div className={s.right}>
      {slides.map((slide, i) => (
        <div key={i} className={cn(s.rightObject, { [s.show]: index === i })}>
          <div className={s.rightImage}>
            <div
              className={s.mainImage}
              style={{
                backgroundImage: `url(${slide.img})`,
                width: slide.width,
              }}
            />
          </div>
          {slide.circles.map((c, i) => (
            <div
              key={i}
              style={{ left: c.left, bottom: c.bottom }}
              className={s.circle1}
            />
          ))}
        </div>
      ))}
    </div>
  );
}

export default RightImage;
