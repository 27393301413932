import React from 'react';

function VideoPlayer({ className, videoSrc }) {
  const src = videoSrc;
  return (
    <div className={className}>
      <video
        playsInline
        autoPlay="autoplay"
        loop="loop"
        muted
        style={{ width: '100%' }}
        preload
      >
        <source src={src.mp4} type="video/mp4" />
        <source src={src.webm} type="video/webm" />
        Your browser does not support the video tag.
      </video>
    </div>
  );
}

export default VideoPlayer;
