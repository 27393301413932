import React, { useCallback, useEffect, useRef, useState } from 'react';
import styled, { css } from 'styled-components';

export function ScrollSideContent(props: { children: React.ReactNode }) {
  const [height, setHeight] = useState('auto');
  const scrollZone = useRef<HTMLDivElement>();

  const padding = 15;

  useWindowResizeCallback(() => {
    if (scrollZone) {
      setHeight(`${scrollZone.current.offsetHeight - 50}px`);
    }
  });

  return (
    <ScrollSileContentRoot style={{ height }}>
      <ScrollZone ref={scrollZone} padding={padding}>
        {props.children}
      </ScrollZone>
    </ScrollSileContentRoot>
  );
}

const ScrollZone = styled.div`
  display: flex;
  padding-bottom: 50px; // hide scrollbar

  flex-wrap: nowrap;
  overflow-x: scroll;

  ${props =>
    props.padding &&
    css`
      padding-right: ${props.padding}px;
      padding-left: ${props.padding}px;
      :after {
        content: '';
        display: block;
        min-width: 1px;
      }
      & > * {
        margin-right: ${props.padding}px;
      }
    `}
`;

const ScrollSileContentRoot = styled.div`
  max-width: 100%;
  overflow: hidden;
`;

function useWindowResizeCallback(callback, deps = []) {
  const f = useCallback(callback, deps);

  useEffect(() => {
    f();
    window.addEventListener('resize', f);
    return () => window.removeEventListener('resize', f);
  }, [f]);
}
