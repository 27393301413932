export default {
  en: [
    {
      title: 'Directory',
      text:
        'Instantly connect with local service providers. Our team conducts ongoing reviews to ensure you find professionals you can trust.',
      pathToImage: require('ui/staticSiteData/mainPage/images/dogImage1.jpg'),
    },
    {
      title: 'Messaging',
      text:
        'Forget to ask your pet’s doctor an important question? Need to follow up on a procedure? With our messaging portal, you can now instantly connect with healthcare providers and service professionals.',
      pathToImage: require('ui/staticSiteData/mainPage/images/petImage2.jpg'),
      backgroundSize: '120%',
    },
    {
      title: 'Scheduling',
      text:
        'Our online booking system allows you to schedule your pet’s next vet visit, confirm an upcoming grooming appointment, and set up obedience school lessons – all in one place, at one time.',
      pathToImage: require('ui/staticSiteData/mainPage/images/petImage.jpg'),
    },

    {
      title: 'Medication Manager',
      text:
        'Refill prescriptions, set reminders, even create dosage notes: Our medication manager removes the stress of remembering when and how to administer your pet’s essential treatment.',
      pathToImage: require('ui/staticSiteData/mainPage/images/catImage.jpg'),
    },
    {
      title: 'Pet Profile',
      text:
        'Create a digital database of your pet’s most important information: Set up preferences, design a bio, even upload photos. Then send the profile instantly to your service providers, ensuring personalized service – every time.',
      pathToImage: require('ui/staticSiteData/mainPage/images/dogImage2.jpg'),
    },
  ],
};
