export default {
  en: {
    title: 'Services',
    menuItems: [
      {
        tabName: 'Veterinarian offices',
        title: 'Veterinarian offices',
        text:
          'We partner exclusively with top-rated, trustworthy medical professionals.',
        leftButton: {
          text: 'Create a profile',
          link: '#',
        },
        rightButton: {
          text: 'List your Business',
          link: '#',
        },
        img: require('ui/staticSiteData/mainPage/servicesBlock/veterinar-image.jpg'),
      },
      {
        tabName: 'Pet-friendly hotels & restaurants',
        title: 'Pet-friendly hotels & restaurants',
        text:
          'When you travel, you want to take the entire family. Feel at ease bringing your pet to one of our reviewed hotels or restaurants.',
        leftButton: {
          text: 'Create a profile',
          link: '#',
        },
        rightButton: {
          text: 'List your Business',
          link: '#',
        },
        img: require('ui/staticSiteData/mainPage/servicesBlock/hotels-image.jpg'),
      },
      {
        tabName: 'Groomers',
        title: 'Pet-friendly hotels & restaurants',
        text:
          'Forget to ask your pet’s doctor an important question? Need to follow up on a procedure? With our messaging portal, you can now instantly connect with healthcare providers and service professionals.',
        leftButton: {
          text: 'Create a profile',
          link: '#',
        },
        rightButton: {
          text: 'List your Business',
          link: '#',
        },
        img: require('ui/staticSiteData/mainPage/servicesBlock/groomers-image.jpg'),
      },
      {
        tabName: 'Trainers',
        title: 'Pet-friendly hotels & restaurants',
        text:
          'Whether you need to send your puppy to obedience school or want to train a service animal, you can find the right trainer through our online database.',
        leftButton: {
          text: 'Create a profile',
          link: '#',
        },
        rightButton: {
          text: 'List your Business',
          link: '#',
        },
        img: require('ui/staticSiteData/mainPage/servicesBlock/trainers-image.jpg'),
      },
      {
        tabName: 'Dog parks & events',
        title: 'Pet-friendly hotels & restaurants',
        text:
          'Need to get out of the house but don’t know where to go? Look through our extensive directory of local dog parks and pet-friendly events.',
        leftButton: {
          text: 'Create a profile',
          link: '#',
        },
        rightButton: {
          text: 'List your Business',
          link: '#',
        },
        img: require('ui/staticSiteData/mainPage/servicesBlock/park-image.jpg'),
      },
      {
        tabName: 'Boarding',
        title: 'Pet-friendly hotels & restaurants',
        text:
          'Need a place for your pet to crash for the night? Easily browse and search boarding locations. While your pet is away know you can contact the service provider at any time and leave a message for them to see with our messaging portal',
        leftButton: {
          text: 'Create a profile',
          link: '#',
        },
        rightButton: {
          text: 'List your Business',
          link: '#',
        },
        img: require('ui/staticSiteData/mainPage/images/catImage.jpg'),
      },
    ],
  },
};
