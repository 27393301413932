import React, { useCallback, useState } from 'react';
import cn from 'classnames';
import styled from 'styled-components';
import { publicApi } from 'api';

import { Button } from 'ui';
import { Form } from 'libs/forms';

import { Error } from 'features/authentication/ui/atoms/forms';

import * as yup from 'yup';

import { InputField } from 'ui/ecosystems/forms';

import { FormFieldsBlock } from 'features/authentication/ui/atoms/forms';

import { Popup } from '../Popup/Popup';
import s from './JoinUsBlock.module.css';
import { mediaPhone } from '../../styles/media';

const schema = yup.object({
  email: yup.string().email('Invalid email').required('required'),
});

export function JoinUsBlock(props) {
  const [isShowPopup, showPopup] = useState(false);
  const [error, setError] = useState(null);

  const closePopup = () => {
    showPopup(false);
  };

  async function handleSubmit(values) {
    try {
      await publicApi.post('/email-subscriptions', values);
      setError(null);
      showPopup(true);
    } catch (e) {
      const error = e.response?.data?.message ?? 'Something wrong';
      setError(error);
    }
  }

  const footerPopup = <ButtonOk onClick={closePopup}>OK</ButtonOk>;

  return (
    <Root {...props}>
      <Title {...props}>Join our Mailing List</Title>
      <Form
        schema={schema}
        onSubmit={handleSubmit}
        render={({ handleSubmit }) => (
          <FormRoot>
            <FormFieldsBlock>
              <EmailField rounded middle name="email" placeholder="E-mail" />
              {error && <Error> {error}</Error>}
              <Button rounded medium onClick={handleSubmit}>
                Join
              </Button>
            </FormFieldsBlock>
          </FormRoot>
        )}
      />
      <Popup
        isOpen={isShowPopup}
        onClose={console.log}
        footer={footerPopup}
        className={s.PopupSuccess}
      >
        Thanks for joining the Pawzr community!
      </Popup>
    </Root>
  );
}

const EmailField = styled(InputField)``;

const FormRoot = styled.div`
  width: 360px;
  ${Error} {
    margin-bottom: 16px;
  }
  ${mediaPhone} {
    width: 220px;
    ${EmailField} {
      height: 40px;
      font-size: 14px;
    }
    ${Button} {
      font-size: 14px;
      height: 40px;
    }
  }
`;

const ButtonOk = styled(Button)`
  width: 100%;
`;

const Title = styled.div`
  font-size: 24px;
  line-height: 32px;
  color: ${props => (props.isWhite ? '#172549' : '#ffffff')};
  font-weight: 500;

  ${mediaPhone} {
    font-size: 20px;
    line-height: 28px;
  }
`;

const Root = styled.div`
  width: 100%;
  height: 360px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: ${props => (props.isWhite ? 'white' : '#c6d6e8')}
  padding: 0 16px;
  ${Title} {
    margin: 0 0 16px;
  }

  ${mediaPhone} {
    height: 250px;
    padding: 0 10px;
  }
`;
