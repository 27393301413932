import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { useAdaptive } from 'ui/styles/addaptive-provider';

export function ReviewCard(props) {
  const isAdaptive = useAdaptive();

  const { children, author, pathToImage, className } = props;
  return isAdaptive ? (
    <ReviewCardMobile className={className}>
      <InfoMobile>{children}</InfoMobile>
      <Author>
        <MobileImage src={pathToImage} />
        <AuthorName>{`— ${author}`}</AuthorName>
      </Author>
    </ReviewCardMobile>
  ) : (
    <ReviewCardDesktop className={className}>
      <DesktopImage src={pathToImage} />
      <InfoDesktop>
        <div>{children}</div>
        <AuthorName>{`— ${author}`}</AuthorName>
      </InfoDesktop>
    </ReviewCardDesktop>
  );
}

const DesktopImage = styled.img`
  border-radius: 16px 0 0 16px;
  width: 230px;
  object-fit: cover;
`;

const AuthorName = styled.div`
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
`;

const InfoDesktop = styled.div`
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #172549;
  padding: 32px 36px 40px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const ReviewCardRoot = styled.div`
  border-radius: 16px;
  color: #172549;
  background: #fff;
`;

const InfoMobile = styled.div``;

const MobileImage = styled.img`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  object-fit: cover;
`;

const Author = styled.div`
  display: grid;
  grid-template-columns: 40px auto;
  grid-gap: 8px;
  align-items: center;
`;

const ReviewCardMobile = styled(ReviewCardRoot)`
  padding: 16px 16px 24px;
  display: flex;
  flex-direction: column;

  ${InfoMobile} {
    margin-bottom: 24px;
  }
`;

const ReviewCardDesktop = styled(ReviewCardRoot)`
  display: flex;
  height: 344px;
`;

ReviewCard.propTypes = {
  author: PropTypes.string.isRequired,
  children: PropTypes.string.isRequired,
  pathToImage: PropTypes.string.isRequired,
};
