export default {
  en: [
    {
      author: 'Anne',
      text:
        "After signing up for Pawzr, I was able to easily create a profile for each of my dogs. Pawzr provided an organized, separate experience for each of my pet's lives. I found the medication reminder particularly useful as I am always worried about forgetting or mixing up their medications, even the flea, tick, and heartworm ones. I also appreciate being able to compare groomers in my area, as well as book and manage appointments using Pawzr.",
      pathToImage: require('ui/staticSiteData/mainPage/images/review1.jpg'),
    },

    {
      author: 'Carmi',
      text:
        'My sweet cat Brandy is getting on in years and seems to be developing tummy problems and has lost weight.  Blood work and x-rays haven’t turned up any specific problems.  Now I’ve been using Pawzr as a diary to keep a record of her “difficulties" in keeping her food down.  It doesn’t seem that a pattern has emerged, but at least I will be able to show the vet what has been going on.  She also stopped eating the prescription food she had been eating so we’ve tried different types of foods hoping to find something she likes.  That is also kept in the diary.  So far her favorite is a bag of Friskies .  Nothing fancy for this girl any more!',
      pathToImage: require('ui/staticSiteData/mainPage/images/review3.jpg'),
    },
    {
      author: 'Brad',
      text: `Since being on pawzr it has been amazing to keep my pet's life organized. Anything I need for the vet I know where to find it. Was also great for traveling with my dog. Helped find dog friendly hotels and restaurants that we could bring her with without having the stress of traveling with a pet. Would recommend pawzr to anyone with a pet.`,
      pathToImage: require('ui/staticSiteData/mainPage/images/review4.jpg'),
    },
    {
      author: 'Alissa',
      text: `Pawzr has been great for keeping track of Peri's vaccinations and vet visits, especially because it means we both have easy access to all of her information. I love having something to refer back to without having to worry about finding time to call her vet! The medication reminders are also super helpful. It's just great to have a central hub to keep track of all of her needs.`,
      pathToImage: require('ui/staticSiteData/mainPage/images/review5.jpg'),
    },
  ],
};
