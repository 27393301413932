import React from 'react';

import { Page, PageConfig } from './types';

export function createPage<T, M>(
  component: React.Component<T> | React.FC<T>,
  config: PageConfig<M>,
): Page<T, M> {
  const page = component;
  // @ts-ignore
  page.getInitialData = config.getInitialData;
  // @ts-ignore
  page.model = config.model;
  // @ts-ignore
  page.renderMetaTags = config.renderMetaTags;
  // @ts-ignore
  return page;
}
