import React from 'react';
import styled from 'styled-components';
// import Slider from 'react-slick';
import loadable from '@loadable/component';

import { useMedia } from 'ui/styles/media';
import { ScrollSideContent } from 'libs/react-scroll-side-content';

import { AdaptiveProvider } from 'ui/styles/addaptive-provider';

import { useMediaQuery } from 'react-responsive';

import { ReviewCard } from '../ReviewCard/ReviewCard';
import reviewCardsData from '../../staticSiteData/mainPage/reviewCard';
import s from './ReviewSlider.module.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './ReviewSlider.css';

const Slider = loadable(() => import('react-slick'));

const settings = {
  className: s.wrapper,
  centerMode: true,
  infinite: true,
  slidesToShow: 1,
  speed: 500,
  swipeToSlide: true,
  focusOnSelect: true,
  dots: true,
  variableWidth: true,
};

function ReviewSlider({ className }) {
  const isPhone = useMediaQuery({ maxWidth: 991 });

  return (
    <AdaptiveProvider mobile={991}>
      <div className={className}>
        {isPhone ? (
          <ScrollSideContent>
            {reviewCardsData.en.map(data => (
              <MobileReview
                key={data.author}
                author={data.author}
                pathToImage={data.pathToImage}
              >
                {data.text}
              </MobileReview>
            ))}
          </ScrollSideContent>
        ) : (
          <Slider {...settings}>
            {reviewCardsData.en.map(data => (
              <ReviewCard
                key={data.author}
                className={s.slideWrapper}
                author={data.author}
                pathToImage={data.pathToImage}
              >
                {data.text}
              </ReviewCard>
            ))}
          </Slider>
        )}
      </div>
    </AdaptiveProvider>
  );
}

const MobileReview = styled(ReviewCard)`
  min-width: calc(100vw - 30px);
`;

export default ReviewSlider;
