import styled from 'styled-components';

export const TwoInputsGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 8px;
`;

export const FormFieldsBlock = styled.div`
  display: grid;
  grid-gap: 16px;
`;

export const Error = styled.div`
  font-size: 14px;
  line-height: 21px;
  color: var(--error);
  text-align: center;
`;
